<template>
  <div class="m-t-lg">
    <div class="pos-r w-100 text-left" style="height: 24px;">
      <span class="font-sm font-white font-bold m-l-xs">渠道收入占比</span>
      <img :src="smallMark" class="pos-a bottom-0 left-0 w-100">
    </div>
    <div class="m-t-md">
      <ve-pie :data="ProportionIncome" :extend="ringExtend" width="100%" height="220px" :key="newKey" />
    </div>
  </div>
</template>

<script>
import VePie from 'v-charts/lib/pie.common';
export default {
  name: 'channelRevenue',
  components: {
    VePie
  },
  props: {
    smallMark: {
      type: String,
      default: ''
    },
    proportion: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      ProportionIncome: {
        columns: ['渠道', '收入'],
        rows: []
      },
      ringExtend: {
        label: {
          formatter: '{b}: {d}%',
          color: '#fff',
          fontSize: 10
        },
        series: {
          radius: '70%',
          center: ['50%', '55%'],
          startAngle: 40,
          avoidLabelOverlap: true
        }
      },
      newKey: new Date().getTime()
    }
  },
  created() {
    this.ProportionIncome.rows = this.proportion.rows;
    this.ProportionIncome.color = this.proportion.color;
  }
}
</script>

<style scoped>

</style>
