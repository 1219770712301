<template>
  <div class="m-t-md">
    <div class="pos-r w-100 text-left" style="height: 24px;">
      <span class="font-sm font-white font-bold m-l-xs">地区排行</span>
      <img :src="smallMark" class="pos-a bottom-0 left-0 w-100">
    </div>
    <div>
      <div class="w-100 font-xs m-t-md text-left" style="color:#9D9DA2;">
        <div class="font-white font-sm m-b-sm">地区总收入</div>
        <div><span style="font-size: 30px;color: #44C8FB;" class="m-r-sm">{{ totalRegionalRevenue.toFixed(2) }}</span>元</div>
      </div>
      <dv-charts :option="regionIncome" style="width: 100%;height: 220px;" :key="newKey" />
<!--      <ve-histogram :data="regionIncome" style="width: 100%;height: 220px;" :key="newKey"></ve-histogram>-->
    </div>
  </div>
</template>

<script>
// import veHistogram from 'v-charts/lib/histogram.common';
import {
  getTotalRegionalIncome,
  getLgAreaListData
} from '@/api/queryData';
export default {
  name: 'totalRegionalRevenue',
  components: {
    // veHistogram
  },
  props: {
    smallMark: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      regionIncome: {
        xAxis: {
          data: [],
          axisLine: {
            style: {
              stroke: '#737C84'
            }
          },
          axisTick: {
            style: {
              stroke: '#737C84'
            }
          },
          nameTextStyle: {
            fill: '#737C84'
          },
          axisLabel: {
            show: true,
            style: {
              fill: '#D6EAFC',
              fontSize: 8,
              rotate: 60,
            }
          }
        },
        yAxis: {
          // name: '销售额',
          data: 'value',
          axisLine: {
            style: {
              stroke: '#737C84'
            }
          },
          axisTick: {
            style: {
              stroke: '#737C84'
            }
          },
          nameTextStyle: {
            fill: '#737C84'
          },
          axisLabel: {
            show: true,
            style: {
              fill: '#D6EAFC'
            }
          },
          splitLine: {
            show: false
          }
        },
        grid: {
          left: 60,
          top: 30,
          right: 0,
          bottom: 70,
          containLabel: true
        },
        series: [
          {
            data: [],
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              offset: [0, -10],
              style: {
                fill: '#fff'
              }
            }
          }
        ]
      },
      countryList: [],
      totalRegionalRevenue: 0,
      newKey: new Date().getTime()
    }
  },
  created() {
    this.getAllCountry();
  },
  methods: {
    // 获取所有国家
    getAllCountry() {
      getLgAreaListData({ level: 0 }).then(res => {
        if (res.code === 200) {
          this.countryList = res.data;
          this.getRegionIncome();
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 获取地区总收入
    getRegionIncome() {
      getTotalRegionalIncome().then(res => {
        if (res.code === 200) {
          this.totalRegionalRevenue = 0;
          this.regionIncome.xAxis.data = [];
          this.regionIncome.series[0].data = [];
          res.data.map(item => {
            let name = '';
            if (this.countryList.length > 0) {
              this.countryList.map(list => {
                if (item.orderDate === list.twoCode) {
                  if (item.profits !== 0) {
                    name = list.nameCn;
                    this.regionIncome.xAxis.data.push(name);
                  }
                }
              });
            } else {
              this.regionIncome.xAxis.data.push(item.orderDate);
            }
            this.totalRegionalRevenue = this.totalRegionalRevenue + item.profits;
            if (item.profits !== 0) {
              this.regionIncome.series[0].data.push(item.profits);
            }
          });
          this.newKey = new Date().getTime();
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>

</style>
