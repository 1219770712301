<template>
  <div>
    <div class="pos-r w-100 text-left" style="height: 24px;">
      <span class="font-sm font-white font-bold m-l-xs">渠道总收入</span>
      <img :src="smallMark" class="pos-a bottom-0 left-0 w-100">
    </div>
    <div class="w-100">
      <dv-scroll-board :config="channelData" class="w-100" style="height: 260px;" :key="newKey" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'totalChannelRevenue',
  props: {
    smallMark: {
      type: String,
      default: ''
    },
    channelData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      newKey: new Date().getTime()
    }
  }
}
</script>

<style scoped>

</style>
