<template>
  <div>
    <div class="pos-r w-100 text-left" style="height: 24px;">
      <span class="font-sm font-white font-bold m-l-xs">年度情况展示</span>
      <img :src="smallMark" class="pos-a bottom-0 left-0 w-100">
    </div>
    <div class="d-flex">
      <div style="height: 170px;width: 170px;" class="pos-r ringGraph m-t-lg">
        <img :src="yearImg" class="w-100 pos-a top-0 left-0" style="height: 100%;">
        <dv-active-ring-chart :config="ringGraph1" style="width:160px;height:160px;margin-top: 5px;margin-left: 4px;" />
      </div>
      <div class="m-t-lg flex-1 p-l-md p-t-sm">
        <div class="m-b-md text-left">
          <div class="font-sm font-white m-b-xs">{{ totalOrder.orderDate }}年度订单总量</div>
          <div style="color: #44C8FB;font-size: 30px;">{{ totalOrder.count }} <span class="font-white font-sm">单</span></div>
        </div>
        <div class="text-left">
          <div class="font-sm font-white m-b-xs">{{ totalRevenue.orderDate }}年度订单总收入</div>
          <div style="color: #44C8FB;font-size: 30px;">{{ totalRevenue.profits }}<span class="font-white font-sm">元</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import yearImg from '@/assets/image/yearImg.svg';
import {
  getTotalAnnualOrders,
  getTotalYearOrderRevenue
} from '@/api/queryData';
export default {
  name: 'yearSituation',
  props: {
    smallMark: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      yearImg,
      ringGraph1: {
        data: [
          {
            name: '周口',
            value: 120
          },
          {
            name: 'laowu',
            value: 120
          }
        ],
        color: ['#48BEEB','#333333'],
        activeRadius: '55%',
        radius: '55%',
        showOriginValue: false,
        lineWidth: 10
      },
      totalOrder: {
        count: 0,
        orderDate: ''
      },
      totalRevenue: {
        profits: 0,
        orderDate: ''
      },
    }
  },
  created() {
    this.getTotalOrder();
  },
  methods: {
    // 获取总单和总收入
    getTotalOrder() {
      const date1 = (new Date()).getFullYear();
      getTotalAnnualOrders().then(res => {
        if (res.code === 200) {
          res.data.map(item => {
            if (item.orderDate === date1.toString()) {
              this.totalOrder = item;
            }
          })
        }
      }).catch(err => {
        console.log(err);
      });
      getTotalYearOrderRevenue().then(res => {
        if (res.code === 200) {
          res.data.map(item => {
            if (item.orderDate === date1.toString()) {
              this.totalRevenue = item;
            }
          })
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>

</style>
