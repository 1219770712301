<template>
  <div style="background: #071522!important;width: 100%;height: 100%;">
    <dv-full-screen-container>
      <div class="w-100 pos-f top-0 left-0 h-100" :style="{'background-image': 'url('+ gridBG + ')'}">
        <div class="d-flex w-100 h-100" style="background: radial-gradient(rgba(26,33,57,0.3) 5%, rgba(9,10,20,0.8) 60%);">
          <div class="flex-2"></div>
          <div style="flex: 5" class="pos-r">
            <div class="rotationLine pos-a top-0 left-0 w-100 h-100 rotationLine1"></div>
            <div class="rotationLine pos-a top-0 left-0 w-100 h-100 rotationLine2"></div>
            <dv-flyline-chart :config="mapOfChina" style="width: 95%;height: 90%;margin: 0 auto;" />
          </div>
          <div class="flex-2"></div>
        </div>
      </div>
      <div class="pos-r m-t-lg" style="z-index: 999;">
        <div class="font-white pos-r screenTitle p-w-lg text-left">
          <img :src="titleLine" class="pos-a top-0 left-0">
          <span class="font-xl font-bold ">安骏订单业务大屏</span>
          <span class="float-r">{{ currentTime }}</span>
        </div>
        <div class="d-flex p-w-md m-t-md">
          <div class="flex-2">
<!--            年度情况展示-->
            <year-situation :smallMark="smallMark" />
<!--            月度订单变化-->
            <monthly-order-change :smallMark="smallMark" />
<!--            渠道总收入-->
            <total-channel-revenue :smallMark="smallMark" :channelData="channelData" :key="newKey" />
          </div>
          <div style="flex: 4">
          </div>
          <div class="flex-2">
<!--            客户贡献排行-->
            <customer-contribution :smallMark="smallMark" />
<!--            渠道收入占比-->
            <channel-revenue :smallMark="smallMark" :proportion="ProportionIncome" :key="newKey" />
<!--            地区排行-->
            <regional-ranking :smallMark="smallMark" />
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import titleLine from '@/assets/image/titleLine.png';
import gridBG from '@/assets/image/gridBG.png';
import smallMark from '@/assets/image/smallMark.png';
import chinaMap from '@/assets/image/ChinaMap.png';
import {
  getChannelRevenue
} from '@/api/queryData';
import { compare } from '@/utils/public';
import yearSituation from '@/views/orderBusiness/comm/yearSituation';
import monthlyOrderChange from '@/views/orderBusiness/comm/monthlyOrderChange';
import totalChannelRevenue from '@/views/orderBusiness/comm/totalChannelRevenue';
import customerContribution from '@/views/orderBusiness/comm/customerContribution';
import channelRevenue from '@/views/orderBusiness/comm/channelRevenue';
import regionalRanking from '@/views/orderBusiness/comm/regionalRanking';
import { setDateTime3 } from '@/utils/date_util';
export default {
  name: 'index',
  components: {
    yearSituation,
    monthlyOrderChange,
    totalChannelRevenue,
    customerContribution,
    channelRevenue,
    regionalRanking
  },
  data() {
    return {
      titleLine,
      gridBG,
      smallMark,
      ProportionIncome: {
        rows: [],
        color: []
      },
      newKey: new Date().getTime(),
      color: [
        '#BF2EED', '#2EEAED', '#6FED2E', '#ED5D2E',
        '#3779FD', '#FFA523', '#F52E5A', '#37FDA3',
        '#2E3BED', '#E8ED2E', '#ED392E', '#08054D',
        '#0DFC43', '#2E3903', '#2E0331'
      ],
      currentTime: '',
      channelData: {
        header: [''],
        data: [],
        rowNum: 6,
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent'
      },
      mapOfChina: {
        centerPoint: [0.52, 0.54],
        points: [
          [0.5, 0.05],
          [0.33, 0.08],
          [0.29, 0.15],
          [0.13, 0.23],
          [0.48, 0.18],
          [0.36, 0.21],
          [0.62, 0.21],
          [0.56, 0.24],
          [0.37, 0.26],
          [0.55, 0.71],
          [0.66, 0.72],
          [0.37, 0.29],
          [0.20, 0.22],
          [0.68, 0.41],
          [0.58, 0.68],
          [0.60, 0.77],
          [0.70, 0.80],
          [0.40, 0.60],
          [0.20, 0.40],
          [0.05, 0.50],
          [0.70, 0.1],
        ],
        bgImgUrl: chinaMap
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      setInterval(() => {
        const date = new Date();
        this.currentTime = setDateTime3(date);
      }, 1000);
      // 获取渠道总收入
      this.getChannelData();
    },
    // 获取渠道总收入
    getChannelData() {
      getChannelRevenue().then(res => {
        if (res.code === 200) {
          let allCount = 0;
          this.channelData.data = [];
          const array = res.data.sort(compare('profits'));
          this.ProportionIncome.rows = array.map(item => {
            allCount = allCount + item.profits;
            const data = {
              '渠道': item.orderDate,
              '收入': item.profits
            };
            return data;
          });
          this.$nextTick(() => {
            array.map(item => {
              const itemData = ((item.profits / allCount) * 100).toFixed(2);
              this.ProportionIncome.color = this.color;

              let html = '';
              html += '<div class="d-flex a-center p-w-xs channelBg pos-r w-100">' +
                '<div class="font-white font-xs ellipsis pos-r" :title="item.orderDate" style="width: 100px;z-index: 100">' + item.orderDate + '</div>' +
                '<div class="channelStyle flex-1 a-center" style="overflow: hidden">' +
                '<div style="height: 15px;background: linear-gradient(to right,#44C8FB,#A5E3FA);overflow: hidden;width:'+ itemData +'%">';
              for (let i = 0; i < 60; i++) {
                html += '<span class="d-inline-block percentage"></span>';
              }
              html += '</div>' +
                '</div>' +
                '<div style="width: 70px;" class="d-flex font-white j-end font-xs text-right">' + item.profits + '</div>' +
                '</div>';
              const list = [html];
              this.channelData.data.push(list);
            });
            this.newKey = new Date().getTime();
          })
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style>
  .rotationLine{
    margin: 0 auto;
    border: 3px dashed cyan;
    border-radius: 50%;
    transform-style: preserve-3d;
    transform-origin: 50%;
  }
  .rotationLine1 {
    animation: rotate_change1 300s linear infinite;
  }
  .rotationLine2{
    animation: rotate_change2 300s linear infinite;
  }
  @keyframes rotate_change1{
    0%{
      transform:rotate3d(1, -1, 1, 140deg) rotateZ(0deg) skew(0deg) scale(1);
    }
    100%{
      transform:rotate3d(1, -1, 1, 140deg) rotateZ(360deg) skew(0deg) scale(1);
    }
  }
  @keyframes rotate_change2{
    0%{
      transform:rotate3d(-1, -1, 1, 140deg) rotateZ(0deg) skew(0deg) scale(1);
    }
    100%{
      transform:rotate3d(-1, -1, 1, 140deg) rotateZ(360deg) skew(0deg) scale(1);
    }
  }
  .percentage{
    margin-left: 3px;
    height: 100%;
    width: 2px;
    background:#071522;
    margin-bottom: 10px;
  }
  .channelBg {
    height: 30px;
  }
  .channelBg::before{
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 30px;
    width: 100%;
    background-image: url(../../assets/image/channelBorder.png);
    background-size: 100% 100%;
    transform:rotate(180deg)
  }
  .screenTitle{
    height: 46px;
    line-height: 46px;
    background: rgba(18,57,137,0.5);
  }
  .ringGraph .active-ring-info{
    display: none!important;
  }
</style>
