<template>
  <div>
    <div class="m-t-lg">
      <div class="pos-r w-100 text-left" style="height: 24px;">
        <span class="font-sm font-white font-bold m-l-xs">月度订单变化</span>
        <img :src="smallMark" class="pos-a bottom-0 left-0 w-100">
      </div>
      <dv-charts :option="lineChart" :key="newKey" style="height: 260px;" />
    </div>
  </div>
</template>

<script>
import {
  getMonthOrders
} from '@/api/queryData';
export default {
  name: 'monthlyOrderChange',
  props: {
    smallMark: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      lineChart: {
        xAxis: {
          data: [],
          axisLine: {
            style: {
              stroke: '#737C84'
            }
          },
          axisTick: {
            style: {
              stroke: '#737C84'
            }
          },
          axisLabel: {
            show: true,
            style: {
              fill: '#D6EAFC',
            }
          }
        },
        yAxis: {
          name: '订单量',
          data: 'value',
          splitLine: {     //网格线
            show: false
          },
          axisLine: {
            style: {
              stroke: '#737C84'
            }
          },
          axisTick: {
            style: {
              stroke: '#737C84'
            }
          },
          nameTextStyle: {
            fill: '#737C84'
          },
          axisLabel: {
            show: true,
            style: {
              fill: '#D6EAFC',
            }
          }
        },
        grid: {
          left: 60,
          top: 40,
          right: 10,
          bottom: 40
        },
        series: [
          {
            data: [],
            type: 'line',
            lineArea: {
              show: true,
              gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
            },
            label: {
              show: true
            }
          }
        ]
      },
      newKey: new Date().getTime()
    }
  },
  created() {
    this.getMonthOrderData();
  },
  methods: {
    // 获取月度订单变化
    getMonthOrderData() {
      getMonthOrders().then(res => {
        if (res.code === 200) {
          let date = [];
          let count = [];
          res.data.map(item => {
            date.push(item.orderDate);
            count.push(item.count);
          });
          this.lineChart.xAxis.data = date;
          this.lineChart.series[0].data = count;
          this.newKey = new Date().getTime();
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>

</style>
