<template>
  <div>
    <div class="pos-r w-100 text-left" style="height: 24px;">
      <span class="font-sm font-white font-bold m-l-xs">客户贡献排行</span>
      <img :src="smallMark" class="pos-a bottom-0 left-0 w-100">
    </div>
    <div class="m-t-md">
      <dv-scroll-board :config="contribution" class="w-100" style="height: 180px;" :key="newKey" />
    </div>
  </div>
</template>

<script>
import { compare } from '@/utils/public';
import {
  getCustomerContributions
} from '@/api/queryData';
export default {
  name: 'customerContribution',
  props: {
    smallMark: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      contribution: {
        header: ['', '<div class="text-right font-xs" style="color:#9D9DA2;">单位：元</div>'],
        data: [],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent'
      },
      newKey: new Date().getTime()
    }
  },
  created() {
    this.getContribution();
  },
  methods: {
    // 获取客户贡献排行
    getContribution() {
      getCustomerContributions().then(res => {
        if (res.code === 200) {
          this.contribution.data = [];
          const array = res.data.sort(compare('profits'));
          array.map(item => {
            const list = [item.orderDate, `<div class="text-right">${ item.profits.toFixed(2) }</div>`];
            this.contribution.data.push(list);
          });
          this.newKey = new Date().getTime();
        }
      }).catch(err => {
        console.log(err);
      });
    },
  }
}
</script>

<style scoped>

</style>
